window.$ = window.jQuery = require("jquery");
require('select2');
var lozad = require('lozad');
require('./lib/jquery.maskedinput.js');
require('./lib/jquery.fancybox.min');
require('./lib/jquery.cookie-1.4.1.min.js');
//require('./scripts/user_check.js');
require('./lib/simplebar.js');
require('./lib/jquery.validate.js');
require('./lib/jquery-ui.min.js')
require('./lib/jquery.ui.touch-punch.min.js')
require('./scripts/initPopups.js');
require('./includes/formHandlers')
require('./scripts/common.js');
require('./scripts/configurator.js');
// require('./scripts/forms.js');
require('./scripts/compare.js');
require('./scripts/sliders.js');
// require('./scripts/slider.js');
// require('./scripts/timer.js')
// require('./scripts/map.js');

$(document).ready(function (){
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
    let mapPosition = $('#map').offset().top

    let mapIframe = `
<iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A06857f1d667cd8325e2812595b240505848976a08d1d185451aae3c4b7f2dfea&amp;source=constructor" width="100%" height="690" frameborder="0"></iframe>
`
    let replaced = false;



    $(window).scroll(function () {
        if (mapPosition - 2500 <= $(window).scrollTop() && !replaced)
        {
            $('#map').html(mapIframe);
            replaced = true;
        }
    })
});

// favorite icon
jQuery(document).ready(function () {

    $('.js-fav').click(function (e) {
        e.preventDefault();
        let id = $(this).data('favorites-id');

        let _this = $(this);

        $.ajax({
            method: "GET",
            url: `/favorites/${id}`,
            success: function (res) {
                let count = parseInt(res.data);
                $('.fav-icon__num').text(count);
                _this.toggleClass('active');
                if (count > 0)
                    $('.header__fav').addClass('active');
                else
                    $('.header__fav').removeClass('active');
            }
        });
    });

    $('.js-compare').click(function (e) {
        e.preventDefault();
        let id = $(this).data('compare-id');
        let _this = $(this);

        $.ajax({
            method: "GET",
            url: `/compare/${id}`,
            success: function (res) {
                let count = parseInt(res.data);
                $('.compare-icon__num').text(count);
                _this.toggleClass('active');
                if (count > 0)
                    $('.header__compare').addClass('active');
                else
                    $('.header__compare').removeClass('active');
            }
        })
    });
    // FILTER FORM SUBMIT HANDLER

    // jQuery('select[name=clientbrand]').on('change', function () {
    //     let selectedBrand = jQuery(this).val();
    //     jQuery.ajax({
    //         method: "GET",
    //         url: `/api/dictionary/getModel/jQuery{selectedBrand}`,
    //         success: function (data) {
    //             jQuery('select[name=clientmodel]').html(data);
    //         }
    //     })
    // })


    // jQuery('select[name=brand]').val("0").trigger('change');

    let transmissionInput = jQuery('select[name=transmission]');

    if (transmissionInput.length > 0)
        jQuery(transmissionInput).val(0).trigger('change');

    // BRAND INPUT CHANGE HANDLER
    jQuery('select[name=brand]').on('change', function () {
        if ($(this).hasClass('no_model'))
            return;
        let selectedBrand = jQuery('select[name=brand] option:selected').data("id");

        let parent = jQuery(this).parents('form').first();
        let modelSelect = jQuery('select[name=model]', parent);

        jQuery(modelSelect)
            .find('option')
            .remove()
            .end();

        jQuery.ajax({
            method: "GET",
            url: `/getModel/` + selectedBrand,
            success: function (data) {
                jQuery(modelSelect).html(data);
            }
        });
    });

    jQuery('#trade-in-brand').on('change', function () {
        let selectedBrand = jQuery('#trade-in-brand option:selected').data("id");

        let modelSelect = jQuery('#trade-in-model');

        jQuery(modelSelect)
            .find('option')
            .remove()
            .end();

        jQuery.ajax({
            method: "GET",
            url: `/api/dictionary/models/` + selectedBrand,
            success: function (data) {
                jQuery(modelSelect).html(data);
            }
        });
    });


    jQuery('select[name=model]').on('change', function () {

        // let parent = jQuery(this).parents('form').first();
        // let modelSelect = jQuery('select[name=model]', parent)

        let eqSelect = jQuery('select[name=equipment]');
        let img = jQuery('select[name=model]').find(":selected").data('img');
        let fullName = jQuery('select[name=model]').find(":selected").data('fullName');

        $('.credit-step__photo').html('<img src="' + img + '" alt="">');
        $('#car-credit-name').html(fullName);
        $('#car-credit-itog-name').html(fullName);

        let colors = jQuery('select[name=model]').find(":selected").data('colors');

        $('.credit-step__photo').html('<img src="' + img + '" alt="">');
        drawCarImagesDOM(colors);
        drawCarColorsDOM(colors);
        if (jQuery(eqSelect).length > 0)
            getEquipmentData(jQuery(this));
    });

    function drawCarImagesDOM(images) {
        let el = $('#car-images');

        el.html('');

        for (let key in images) {
            console.log(key);
            if (key == 0) {
                $('#car-credit-mob-image').attr("src", '/storage' + images[key]['image'])
                el.append($("<img>").attr("src", '/storage' + images[key]['image']).attr("data-id", images[key]['rgb']).attr("class", "active"));
            } else {
                el.append($("<img>").attr("src", '/storage' + images[key]['image']).attr("data-id", images[key]['rgb']));
            }
        }
    }

    function drawCarColorsDOM(images) {
        let el = $('#car-colors');

        el.html('');

        for (let key in images) {
            console.log(key);
            if (key == 0) {
                el.append($("<div></div>")
                    .attr("data-id", images[key]['rgb'])
                    .attr("class", "colors__color active")
                    .append($("<div></div>")
                        .attr("class", "colors__color-circle")
                        .attr("style", "background-color: #" + images[key]['rgb'] + ";")
                    )
                    .append($("<div></div>")
                        .attr("class", "colors__color-value")
                        .text(images[key]['name'])
                    )
                );
            } else {
                el.append(
                    $("<div></div>")
                        .attr("data-id", images[key]['rgb'])
                        .attr("class", "colors__color")
                        .append($("<div></div>")
                            .attr("class", "colors__color-circle")
                            .attr("style", "background-color: #" + images[key]['rgb'] + ";")
                        )
                        .append($("<div></div>")
                            .attr("class", "colors__color-value")
                            .text(images[key]['name'])
                        )
                );
            }
        }

        $('.colors__color-circle').on('click', function(){
            let colorParent = $(this).closest('.colors__color');
            let colorVal = colorParent.find(".colors__color-value").html();
            $("#car-color-name").html("Цвет: " + colorVal);
            $('.colors__color').removeClass("active");
            colorParent.addClass("active");
            $('.dif-colors__images img').removeClass("active");
            $('.dif-colors__images img[data-id=' + colorParent.data('id') + ']').addClass("active");
        });
    }

    jQuery('select[name=equipment]').on('change', function () {

        // let parent = jQuery(this).parents('form').first();
        // let modelSelect = jQuery('select[name=model]', parent)

        // let eqSelect = jQuery('select[name=equipment]');
        let option = $('select[name="equipment"] option:selected');
        // console.log(data)
        $('#car-equipment-old-price').text(option.data('price'));
        $('#car-credit-equipment').text(option.data('equipmentName'));
        $('#car-credit-itog-equipment').text(option.data('equipmentName'));
        $('#car-credit-equipment-block').attr('style', 'display:block;');
        // $('#').text(option.data('monthly'))
        $('#car-equipment-new-price').text(option.data('discount'));

        if ($('#credit-range-block').hasClass('disable')) {
            $('#credit-range-block').removeClass('disable');
        }

    });

    function getEquipmentData(modelSelect) {
        let model = $(modelSelect).select2('data');
        if (model.length < 1)
            return;

        let versionSelect = $('select[name=equipment]')

        $(versionSelect)
            .find('option')
            .remove()
            .end()

        $.ajax({
            method: "GET",
            url: `/getEquipmentSets/${model[0].id}`,
            success: function (res) {
                // FILL EQUIPMENT_SET SELECT INPUT
                let eqs = res.data.eq

                for (let key in eqs) {
                    $(versionSelect)
                        .append($("<option></option>")
                            .attr("value", key)
                            .attr("data-price", res.data['price'][key] + ' руб')
                            .attr("data-equipment-name", eqs[key])
                            .attr("data-price-raw", res.data['skid'][key])
                            .attr("data-discount", res.data['skid'][key] + ' руб')
                            .attr("data-monthly", res.data['mon'][key] + ' руб/мес')
                            .text(eqs[key]));
                }

                $(versionSelect).val(0).trigger('change');
                $(versionSelect).val($('select[name="equipment"] option:first').val()).trigger('change');

                let priceRaw = Number(String($('.credit-cols .select2[name="equipment"] option:selected').data('priceRaw')).replace(/\s/g, ''));
                let percent = $("#percent-range").slider("value");
                let months = $("#term-range").slider("value");

                let formattedPayment = getInitialPayment(priceRaw, percent);

                $('#vznos-input').val(formattedPayment);

                let monthly = $('#car-credit-itog-monthly');
                let vznos = $('#car-credit-itog-vznos');
                let monthlyValue = priceFormat(creditCalc(priceRaw, months, formattedPayment));

                monthly.html(monthlyValue + ' руб/мес')
                vznos.html(priceFormat(formattedPayment + ' руб'));
            }
        })
    }


    function priceFormat(str) {
        return String(str).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    }

    function getInitialPayment(price, percent) {
        return Math.ceil(Math.round(price * (percent / 100)));
    }

    function creditCalc(price, months = 84, initialPayment = 0) {
        price -= initialPayment;
        if (price < 0) {
            return 0;
        }

        let fullCreditPrice = price * 0.9 * 1.12;
        return Math.ceil(Math.round((fullCreditPrice) / months) / 100) * 100;
    }

    // cities

    // jQuery('select[name=cities]').on('change', function () {
    //     location.assign($(this).val());
    // });

    $('.select-city-domain').click(function (){
        location.assign($(this).data('sub'));
    });

    if ($('.filters-screen').length > 0)
    {
        let url_string = window.location.href
        let url = new URL(url_string);

        let brand = url.searchParams.get("brand");
        $('select[name=brand]').val(brand).trigger('change');

        let priceBefore = url.searchParams.get("priceBefore");
        $('select[name=priceBefore]').val(priceBefore).trigger('change');

        let transmission = url.searchParams.get("transmission");
        $('select[name=transmission]').val(transmission).trigger('change');

        let body = url.searchParams.get("body");
        $('select[name=body]').val(body).trigger('change');

        let country = url.searchParams.get("country");
        $('select[name=country]').val(country).trigger('change');
    }

    $('#filter-form').one('submit', function (e) {
        e.preventDefault();
        let brandVal = $('select[name=brand]').val();
        let priceVal = $('select[name=priceBefore]').val();
        let countryVal = $('select[name=country]').val();

        let brandSlug = $('select[name=brand] option:selected').data("slug");
        let priceSlug = $('select[name=priceBefore] option:selected').val();

        let url = '';

        if (brandSlug)
            url = brandSlug;
        else if (priceSlug && priceSlug !== '0')
            url = priceSlug;

        if (brandSlug && priceSlug && priceSlug !== '0')
            url = brandSlug + '/' + priceSlug;

        // $(this).attr('action', '/filter/' + url);
        let bodyVal = $('select[name=body]').val();
        let transmissionVal = $('select[name=transmission]').val();

        if ((parseInt(bodyVal) === 0 || !bodyVal)
            && (parseInt(transmissionVal) === 0 || !transmissionVal)
            && (parseInt(priceVal) === 0 || !priceVal)
            && (parseInt(brandVal) === 0 || !brandVal)
            && (parseInt(countryVal) !== 0 || countryVal))
        {
            if (countryVal !== undefined) {
                window.location.href = '/country/' + countryVal;
                return false;
            }
        }

        if ((parseInt(bodyVal) === 0 || !bodyVal)
            && (parseInt(transmissionVal) === 0 || !transmissionVal))
        {
            window.location.href = '/filter/' + url;
            return false;
        }


        $(this).submit();
    });
});

