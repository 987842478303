$(document).ready(function () {

    function reachGoals(formID, cssFormId) {
        ym(window.metrica_id,'reachGoal','all');
        ym(window.metrica_id, 'reachGoal', formID);

        if (cssFormId == 'car-credit-version-popup') { //для Оксаны цель
            ym(window.metrica_id, 'reachGoal', 'goal_credit_short');
        }

        gtag('event', 'conversion', {'send_to': 'AW-760706226/JrTRCIbwuoMDELLp3eoC' });

        /*var _tmr = window._tmr || (window._tmr = []);
        _tmr.push({ id: window.mailru_id, type: "reachGoal", goal: "artoxlead"});

        gtag('event', 'zayavka', { 'event_category': 'form', 'event_action': 'zayavka'});*/

    }


    $('input[type="tel"]').mask("+7(nnn)nnn-nnnn", {autoclear: false});
    $('input[name="date"]').mask("nn.nn.nnnn", {autoclear: false});

    jQuery.validator.addMethod("requiredselect", function (value, element, arg) {
        return arg != value;
    }, "Выберите один из вариантов");

    jQuery.validator.addMethod('checkMask', function (value, element) {
        return this.optional(element) || /\+\d{1}\(\d{3}\)\d{3}-\d{4}/g.test(value);
    }, 'Телефон указан неверно. Пример: +7(901)123-4567');

    jQuery.validator.addMethod('checkMaskDate', function (value, element) {
        return this.optional(element) || /\d{2}\.\d{2}\.\d{4}/g.test(value);
    }, 'Телефон указан неверно. Пример: 06.12.2012');

    jQuery.validator.addMethod("notnumbers", function (value, element) {
        return this.optional(element) || /^[a-zа-яё\-\s]+$/i.test(value);
    }, "Поле заполнено неверно. Введите только буквы")

    $('.popup .wpcf7-form').each(function () {
        var form = $(this);
        let formID = $(form).data('id');
        let cssFormId = $(form).attr('id');

        var formBtn = form.find('button[type="submit"]');

        if (formID === 'ignore')
            return;

        form.validate({
            errorPlacement: $.noop,
            rules: {
                name: {
                    required: true,
                    notnumbers: true
                },
                // info: 'required',
                phone: {
                    required: true,
                    checkMask: true,
                }
            },
            submitHandler: function () {

                formBtn.addClass('loading').prop('disabled', true);
                $.ajax({
                    url: "/api/offers/" + formID,
                    method: "POST",
                    data: $(form).serialize() + '&ga_id=' + window.ga_id + '&ym_id=' + window.ym_id + '&form_id=' + formID,
                }).always(function (data) {
                    formBtn.removeClass('loading').prop('disabled', false);
                    if (data.success) {
                        $.fancybox.close();
                        setTimeout(function () {
                            $.fancybox.open({
                                src: '#thx-popup',
                                type: 'inline',
                            });
                        }, 500);
                        //
                        reachGoals(formID, cssFormId);
                    } else {
                        $.fancybox.close();

                        if (data.responseJSON.message === 'DUBLICATE') {

                            $.fancybox.open({
                                src: '#dubl-popup',
                                type: 'inline',
                            });

                            return;
                        }

                        $.fancybox.open({
                            src: '#err-popup',
                            type: 'inline',
                        });
                    }
                })
            }
        });
    });

    $('.wpcf7_validate .wpcf7-form').each(function () {
        let form = $(this);
        let formID = $(form).data('id');
        var formBtn = form.find('button[type="submit"]');

        if (formID === 'ignore')
            return;

        form.validate({
            ignore: 'input[type=hidden]',
            errorPlacement: $.noop,
            rules: {
                info: 'required',
                sum: 'required',
                birthdate: {
                    required: true,
                    date: true
                },
                address: 'required',
                brand: 'required',
                model: 'required',
                advertisement: 'equipment_set',
                price: 'required',
                name: {
                    required: true,
                    notnumbers: true
                },
                phone: {
                    required: true,
                    checkMask: true,
                },
                release: {
                    required: true,
                    digits: true,
                },
                mileage: {
                    required: true,
                    digits: true,
                },
                brands: {
                    requiredselect: "",
                },
                models: {
                    requiredselect: "",
                },
                brands2: {
                    requiredselect: "",
                },
                models2: {
                    requiredselect: "",
                },
                equipment: {
                    requiredselect: "",
                },
                transmission: {
                    requiredselect: "",
                },
                release2: {
                    requiredselect: "",
                },
                version: {
                    requiredselect: "",
                },
                present: {
                    requiredselect: "",
                },
                carbody: {
                    requiredselect: "",
                },
            },
            submitHandler: function () {
                formBtn.addClass('loading');
                formBtn.prop('disabled', true);
                $.ajax({
                    url: "/api/offers/" + formID,
                    method: "POST",
                    data: $(form).serialize() + '&ga_id=' + window.ga_id + '&ym_id=' + window.ym_id + '&form_id=' + formID,
                }).always(function (data) {
                    formBtn.removeClass('loading');
                    formBtn.prop('disabled', false);
                    if (data.success) {
                        $.fancybox.open({
                            src: '#thx-popup',
                            type: 'inline',
                        });
                        reachGoals(formID);
                    } else {

                        if (data.responseJSON.message === 'DUBLICATE') {

                            $.fancybox.open({
                                src: '#dubl-popup',
                                type: 'inline',
                            });

                            return;
                        }

                        $.fancybox.open({
                            src: '#err-popup',
                            type: 'inline',
                        });
                    }
                })
            }
        });
    });

    document.addEventListener("formPopupSuccess", function (event) {
        $('.popup').addClass("popup_sent");
        $('.popup__content.popup__content_thx').css({
            'display': 'block'
        });
        setTimeout(function () {
            $.fancybox.close();
        }, 5000);
        setTimeout(function () {
            $('.popup').removeClass("popup_sent");
            $('.popup__content.popup__content_thx').css({
                'display': 'none'
            });
        }, 5300)
    });

    document.addEventListener("formPopupError", function (event) {
        $('.popup').addClass("popup_sent");
        $('.popup__content.popup__content_err').css({
            'display': 'block'
        });
        setTimeout(function () {
            $.fancybox.close();
        }, 5000);
        setTimeout(function () {
            $.fancybox.close();
            $('.popup').removeClass("popup_sent");
            $('.popup__content.popup__content_err').css({
                'display': 'none'
            });
        }, 5300);
    });

})

