import SimpleBar from "simplebar";

$(document).ready(function() {

    const compareTables = document.querySelectorAll(".compare-tables");

    // table scrollbar


    if (document.getElementById('teh-tables')) {
        const simpleBarTeh = new SimpleBar(document.getElementById('teh-tables'),{
            autoHide: false
        });
    }

    if (document.getElementById('equip-tables')) {
        const simpleBarEquip = new SimpleBar(document.getElementById('equip-tables'),{
            autoHide: false
        });
    }

    function addTablesWidth(table) {
        let tableHeader = table.querySelector(".compare-table__header"),
            w1 = tableHeader.querySelector(".compare-table__title").clientWidth,
            w2 = tableHeader.querySelectorAll(".compare-table__model.active")[0].clientWidth,
            num = tableHeader.querySelectorAll(".compare-table__model.active").length;
        let tablesWidth = (w2 * num) + w1;
        table.style.width = tablesWidth + 'px';
    };

    function removeTablesWidth(table, tables) {
        let tableHeader = table.querySelector(".compare-table__header"),
            w1 = tableHeader.querySelector(".compare-table__title").clientWidth,
            w2 = tableHeader.querySelectorAll(".compare-table__model.active")[0].clientWidth,
            w3 = tables.clientWidth,
            num = tableHeader.querySelectorAll(".compare-table__model.active").length;
        let tablesWidth = (w2 * (num - 1)) + w1;
        if ( tablesWidth > w3 ) {
            table.style.width = tablesWidth + 'px';
        } else {
            table.style.width = w3 + 'px';
        }
    };

    // adding-removing new model and highlight different values

    compareTables.forEach( tables => {
        const compareTable = tables.querySelectorAll(".compare-table"),
            row = tables.querySelectorAll(".compare-table__row"),
            checkModel = tables.querySelectorAll(".compare-tables__checkboxes input"),
            checkDifferent =
                tables.querySelector(".js-highlight-row input");

        checkModel.forEach( model => {
            compareTable.forEach( table => {
                const header = table.querySelector(".compare-table__header");

                model.addEventListener("click", function(event) {
                    const modelName = model.value,
                        modelField = header.querySelector('.compare-table__model[data-id="' + modelName + '"]');
                    let modelIndex = 0;
                    for(var i in header.children) {
                        if ( header.children[i] == modelField ) {
                            modelIndex = i;
                        };
                    };

                    if ( event.target.checked == true) {

                        modelField.classList.add("active");

                        row.forEach( item => {
                            item.querySelectorAll(".compare-table__value")[modelIndex-1].classList.add("active");
                        });
                        addTablesWidth(table);
                    } else {
                        modelField.classList.remove("active");

                        row.forEach( item => {
                            item.querySelectorAll(".compare-table__value")[modelIndex-1].classList.remove("active");

                            const rowField = item.querySelectorAll(".compare-table__value.active"),
                                fieldValues = [];

                            if ( checkDifferent.checked == true) {
                                rowField.forEach( field => {
                                    const fieldVal = field.textContent;
                                    fieldValues.push(fieldVal);
                                });

                                for (let i = 0; i < fieldValues.length; i++) {
                                    if (fieldValues[0] !== fieldValues[i]) {
                                        return;
                                    } else {
                                        item.classList.remove("highlighted");
                                    };
                                };
                            };
                        });
                        removeTablesWidth(table, tables);
                    };

                    if ( checkDifferent.checked == true) {
                        row.forEach( item => {
                            const rowField = item.querySelectorAll(".compare-table__value.active"),
                                fieldValues = [];

                            rowField.forEach( field => {
                                const fieldVal = field.textContent;
                                fieldValues.push(fieldVal);
                            });

                            for (let i = 0; i < fieldValues.length; i++) {
                                if (fieldValues[0] !== fieldValues[i]) {
                                    item.classList.add("highlighted");
                                    break;
                                };
                            };
                        });
                    };

                    simpleBarTeh.recalculate();
                    simpleBarEquip.recalculate();
                });
            });
        });

        checkDifferent.addEventListener("click", function (event) {
            if ( event.target.checked == true) {
                row.forEach( item => {
                    const rowField = item.querySelectorAll(".compare-table__value.active");
                    const fieldValues = [];
                    rowField.forEach( field => {
                        const fieldVal = field.textContent;
                        fieldValues.push(fieldVal);
                    });
                    for (let i = 0; i < fieldValues.length; i++) {
                        if (fieldValues[0] !== fieldValues[i]) {
                            item.classList.add("highlighted");
                            break;
                        }
                    }
                });
            } else {
                row.forEach( item => {
                    item.classList.remove("highlighted");
                });
            }
        });
    });
});
