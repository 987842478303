import Swiper, {
    SwiperOptions,
    Navigation,
    Pagination,
    Scrollbar,
    EffectCoverflow
} from 'swiper';

Swiper.use([
    Navigation,
    Pagination,
    Scrollbar,
    EffectCoverflow
]);


$(document).ready(function () {

// sliders

    $(".swiper-cars-simple .swiper-container").each(function () {
        let brand = $(this).data('id');

        let nextEl = '.swiper-cars-simple .btn-next-' + brand;
        let prevEl = '.swiper-cars-simple .btn-prev-' + brand;

        if ('undefined' === typeof (brand)) {
            nextEl = '.swiper-cars-simple .swiper-button-next';
            prevEl = '.swiper-cars-simple .swiper-button-prev';
        }

        new Swiper(this, {
            slidesPerView: 1,
            spaceBetween: 10,
            navigation: {
                // nextEl: $(this).parent().find(".swiper-button-next"),
                // prevEl: $(this).parent().find(".swiper-button-prev")
                nextEl: nextEl,
                prevEl: prevEl,
            },
            breakpoints: {640: {slidesPerView: 2, spaceBetween: 10}, 1024: {slidesPerView: 4, spaceBetween: 20}}
        })
    })

    var swiperBanner = new Swiper('.swiper-banner .swiper-container', {
        slidesPerView: "1",
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-banner .swiper-pagination',
            clickable: true,
        },
    });

    var carsSwiper = new Swiper('.swiper-cars .swiper-container', {
        observer: true,
        observeParents: true,
        slidesPerView: 1,
        slidesPerColumn: 1,
        spaceBetween: 0,
        pagination: {
            el: '.hits-screen .swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.hits-screen .swiper-button-next',
            prevEl: '.hits-screen .swiper-button-prev',
        },
        breakpoints: {
            640: {
                slidesPerView: 2,
                slidesPerColumn: 2,
                spaceBetween: 0,
            },
            1024: {
                slidesPerView: 3,
                slidesPerColumn: 2,
                spaceBetween: 0,
            },
        }
    });

    var colorsSwiper = undefined;

    function initColorsSwiper() {
        var screenWidth = $(window).outerWidth();
        if ((screenWidth < (768)) && (colorsSwiper == undefined)) {
            colorsSwiper = new Swiper('.swiper-colors .swiper-container', {
                slidesPerView: 6,
                navigation: {
                    nextEl: '.swiper-colors .swiper-button-next',
                    prevEl: '.swiper-colors .swiper-button-prev',
                },
                breakpoints: {
                    400: {
                        slidesPerView: 8,
                    },
                }
            })
        } else if ((screenWidth > 767) && (colorsSwiper != undefined)) {
            colorsSwiper.destroy();
            colorsSwiper = undefined;
            $('.swiper-colors .swiper-wrapper').removeAttr('style');
            $('.swiper-colors .swiper-slide').removeAttr('style');
        }
    };
    initColorsSwiper();

    $(window).resize(function () {
        initColorsSwiper();
    });

    var compareHeaderSwiper = new Swiper('.compare-screen__models-line .swiper-container', {
        slidesPerView: "auto",
        speed: 500,
        followFinger: false,
        spaceBetween: 0,
        navigation: {
            nextEl: '.compare-screen .swiper-button-next',
            prevEl: '.compare-screen .swiper-button-prev',
        },
        breakpoints: {
            640: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
            1280: {
                slidesPerView: 4,
            },
        },
        on: {
            slideChange: function () {
                let indx = this.activeIndex;
                compareSwiper.forEach(swiperRow => {
                    swiperRow.slideTo(indx, 500, false);
                });
            },
        }
    });

    var compareSwiper = new Swiper('.compare-table__row .swiper-container', {
        slidesPerView: "auto",
        speed: 500,
        followFinger: false,
        spaceBetween: 0,
        breakpoints: {
            640: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
            1280: {
                slidesPerView: 4,
            },
        },
        on: {
            slideChange: function () {
                let indx = this.activeIndex;
                compareSwiper.forEach(swiperRow => {
                    swiperRow.slideTo(indx, 500, false);
                });
                compareHeaderSwiper.slideTo(indx, 500, false);
            },
        }
    });

    $(window).resize(function () {
        if (window.innerWidth < 768) {
            $(".compare-table:not(:first) .compare-table__content").hide();
        }
    });
    $(window).resize();

    $(".compare-table:not(:first) .compare-table__row .compare-table__values").hide();
    // $(".compare-table:not(:first) .compare-table__row .swiper-compare").hide();


    var swiperReviews = new Swiper('.swiper-reviews .swiper-container', {
        slidesPerView: "auto",
        spaceBetween: 7,
        navigation: {
            nextEl: '.swiper-reviews .swiper-button-next',
            prevEl: '.swiper-reviews .swiper-button-prev',
        },
        breakpoints: {
            768: {
                spaceBetween: 15,
            }
        }
    });

    var swiperPartners = new Swiper('.swiper-partners .swiper-container', {
        slidesPerView: "1",
        // slidesPerColumn: 3,
        spaceBetween: 15,
        navigation: {
            nextEl: '.partners-screen .swiper-button-next',
            prevEl: '.partners-screen .swiper-button-prev',
        },
        breakpoints: {
            640: {
                slidesPerView: 2,
                // slidesPerColumn: 2,
                spaceBetween: 15,
            },
            768: {
                slidesPerView: 4,
                // slidesPerColumn: 3,
                spaceBetween: 15,
            },
            1366: {
                slidesPerView: 6,
                // slidesPerColumn: 4,
                spaceBetween: 15,
            },
        }
    });

    var swiperPartners = new Swiper('.swiper-partners-sm .swiper-container', {
        slidesPerView: "1",
        spaceBetween: 15,
        navigation: {
            nextEl: '.partners-screen-sm .swiper-button-next',
            prevEl: '.partners-screen-sm .swiper-button-prev',
        },
        breakpoints: {
            640: {
                slidesPerView: 3,
                spaceBetween: 15,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 15,
            },
        }
    });

    var swiperPhotos = new Swiper('.swiper-photos .swiper-container', {
        slidesPerView: "auto",
        // loop: true,
        navigation: {
            nextEl: '.photos-slider .swiper-nav .swiper-button-next',
            prevEl: '.photos-slider .swiper-nav .swiper-button-prev',
        },
    });

    var swiperArticlePhotos = new Swiper('.swiper-article-photos .swiper-container', {
        slidesPerView: "1",
        navigation: {
            nextEl: '.swiper-article-photos .swiper-button-next',
            prevEl: '.swiper-article-photos .swiper-button-prev',
        },
        pagination: {
            el: '.swiper-article-photos .swiper-pagination',
            type: 'fraction',
        },
    });

});
