window.showTestDrivePopup = function(model_id) {
    $.fancybox.open({
        src: '#test-drive-popup',
        type: 'inline',
        opts: {
            beforeShow: function (instance, slide) {
                $('.popup__model-id').val(model_id);
            }
        }
    });
}

window.showPricePopup = function(price, credit, brand) {
    $.fancybox.open({
        src: '#price-popup-version',
        type: 'inline',
        opts: {
            beforeShow: function (instance, slide) {
                $('#minimal-price').html(price);
                $('#credit-price-reserve').html(credit);
                $('#brand-finance-price').html(brand);
            }
        }
    });
}

window.showTradeInPopup = function(version_id, img) {
    $.fancybox.open({
        src: '#trade-in-popup',
        type: 'inline',
        opts: {
            beforeShow: function (instance, slide) {
                $('#trade-in-popup .popup__car_id').val(version_id);
                $('#trade-in-popup .popup__new-car').attr('src', '/storage' + img);
            }
        }
    });
}

// TODO show_MODEL_CreditPopup

window.showCreditPopup = function(version_id, img) {
    $.fancybox.open({
        src: '#credit-popup-plate',
        type: 'inline',
        opts: {
            beforeShow: function (instance, slide) {
                $('#credit-popup-plate .popup__car_id').val(version_id);
                $('#credit-popup-plate .popup__new-car').attr('src', '/storage' + img);
            }
        }
    });
}

window.showReserveFirstScreenPopup = function(version_id, img) {
    $.fancybox.open({
        src: '#reserve-popup',
        type: 'inline',
        opts: {
            beforeShow: function (instance, slide) {
                $('#reserve-popup .popup__car_id').val(version_id);
                $('#reserve-popup .popup__small-car').attr('src', '/storage' + img);
            }
        }
    });
}

window.showRoadPopup = function(version_id, img) {
    $.fancybox.open({
        src: '#road-popup',
        type: 'inline',
        opts: {
            beforeShow: function (instance, slide) {
                $('#reserve-popup .popup__car_id').val(version_id);
            }
        }
    });
}

window.showCreditVersionPopup = function(version_id, img, carName, price) {

    if (price === 0) {
        price = $('.car-info__price').data('formatted_price');
    }

    $.fancybox.open({
        src: '#credit-popup-version',
        type: 'inline',
        opts: {
            beforeShow: function (instance, slide) {
                $('#credit-popup-version .popup__car_id').val(version_id);
                $('#credit-popup-version .popup__content .popup__descr').text(carName);
                $('#credit-popup-version .popup__content .popup__price span').text(price);
                $('#credit-popup-version .popup__new-car').attr('src', '/storage' + img);
            }
        }
    });
}

window.showReservePopup = function(version_id, img)
{
    $.fancybox.open({
        src: '#reserve-popup',
        type : 'inline',
        opts : {
            beforeShow : function( instance, slide ) {
                $('#reserve-popup .popup__car_id').val(version_id);
                $('#reserve-popup .popup__content .popup__caption').html('Забронировать авто');
                $('#car-reserve-popup .wpcf7-details').hmtl('Ваш персональный менеджер свяжется с вами в течение 30 минут');
                $('#reserve-popup .popup__small-car').attr('src', '/storage' + img);
            }
        }
    });
}

window.showReservePlatePopup = function(version_id, img, name)
{
    $.fancybox.open({
        src: '#reserve-popup-plate',
        type : 'inline',
        opts : {
            beforeShow : function( instance, slide ) {
                $('#reserve-popup-plate .popup__car_id').val(version_id);
                $('#reserve-popup-plate .popup__caption span').text(name);
                $('#reserve-popup-plate .popup__new-car').attr('src', '/storage' + img);
            }
        }
    });
}

window.showReserveVersionPopup = function(version_id, img, carName, price) {
    if (price === 0) {
        price = $('.car-info__price').data('formatted_price');
    }
    $.fancybox.open({
        src: '#reserve-popup-version',
        type: 'inline',
        opts: {
            beforeShow: function (instance, slide) {
                $('#reserve-popup-version .popup__car_id').val(version_id);
                $('#reserve-popup-version .popup__content .popup__descr').text(carName);
                $('#reserve-popup-version .popup__content .popup__price span').text(price);
                $('#reserve-popup-version .popup__new-car').attr('src', '/storage' + img);
            }
        }
    });
}

window.showPlatesFamilyPopup = function(version_id, img)
{
    $.fancybox.open({
        src: '#family-popup',
        type : 'inline',
        opts : {
            beforeShow : function( instance, slide ) {
                $('#family-popup .popup__car_id').val(version_id);
                $('#family-popup .popup__new-car').attr('src', '/storage' + img);
            }
        }
    });
}

window.showPlatesGuaranteePopup = function(version_id, img)
{
    $.fancybox.open({
        src: '#guarantee-popup',
        type : 'inline',
        opts : {
            beforeShow : function( instance, slide ) {
                $('#guarantee-popup .popup__car_id').val(version_id);
                $('#guarantee-popup .popup__new-car').attr('src', '/storage' + img);
            }
        }
    });
}

window.showPlatesInitialFeePopup = function(version_id, img)
{
    $.fancybox.open({
        src: '#initial-fee-popup',
        type : 'inline',
        opts : {
            beforeShow : function( instance, slide ) {
                $('#initial-fee-popup .popup__car_id').val(version_id);
                $('#initial-fee-popup .popup__new-car').attr('src', '/storage' + img);
            }
        }
    });
}

window.showInstallmentPopup = function(car_id) {
    $.fancybox.open({
        src: '#installment-popup',
        type: 'inline',
        opts: {
            beforeShow: function (instance, slide) {
                $('.popup__car-id').val(car_id);
            }
        }
    });
}
