$(document).ready(function(){

    let _price = 0;

// configurator

    $('.configurator__button').on("click touchstart", function(){
        var index=$(this).closest(".configurator__step").index();
        $('.configurator__steps').css({
            'margin-left': '-'+(index+1)+'00%',
        });
        $('.configurator__nav ul li').each(function() {
            var indexButton = $(this).index();
            if ((index + 1) == indexButton) {
                $(this).addClass('active');
            } else if ((index+1) > indexButton) {
                $(this).removeClass('active');
                $(this).addClass('before-active');
            }	else {
                $(this).removeClass('active').removeClass('before-active');
            }
        });

        $('.summary__total-price').text(_price + ' руб.')
    });

    $('.configurator__button_prev').on("click touchstart", function(){
        var index=$(this).closest(".configurator__step").index();
        $('.configurator__steps').css({
            'margin-left': '-'+(index-1)+'00%',
        });
        $('.configurator__nav ul li').each(function() {
            var indexButton = $(this).index();
            $(this).removeClass('active');
            if ( (index-1) <= indexButton ) {
                $(this).removeClass('before-active');
            }
            if ( (index - 1) == indexButton ) {
                $(this).addClass('active');
            }
        });
    });

    $('.configurator__step_equipment input').change(function(){
        var equipment = $(this).data('id');
        $('.configurator__equipment').removeClass("active");
        // $('.desc-modif').attr("style", "display:none");
        // Изменения js для динамического подставления модификаций под выбранную комплектацию
        /* !--- START EDIT ---! */
        // $('.eq-modif').attr("style", "display:none");
        // $('.eq-modif-desc').attr("style", "display:none");
        var firstId = $(this).data('firstid');
        $('.modifications-radio-buttons').each(function (){
            $(this).hide();
        });
        $('.modifications-desc').each(function (){
            $(this).hide();
        });
        $('#equipment-modification-' + equipment).show();
        $('#equipment-modification-desc-' + equipment).show();
        /* !--- END EDIT ---! */

        $('.configurator__equipment[data-id=' + equipment + ']').addClass("active");
        var summaryVal = $(this).closest('.radio-button').find('.radio-button__label').html();
        $('.summary__item_equipment .summary__value span').html(summaryVal);
    });

    $('.eq-next').on('click', function () {
        // $('.eq-modif').attr("style", "display:none");
        $('.eq-modif-desc').removeClass("active");
        let eqModifNotNone = $(".eq-modif").filter(function() { return $(this).css("display") !== "none" });
        let firstEqModif = $(eqModifNotNone).first();
        $(firstEqModif).find('input').first().prop('checked', true).trigger('change');
        // $('.configurator__step_engine input').trigger('change');
    })

    $('.configurator__step_engine input').change(function(){
        var engine = $(this).data('id');
        var car = $(this).data('carId');
        var firstId = $(this).data('firstId');
        $('.configurator__engine').removeClass("active");
        $('.configurator__engine[data-id="' + engine + '"]').addClass("active");
        var summaryVal = $(this).closest('.radio-button').find('.radio-button__label').html();
        $('.summary__item_engine .summary__value span').html(summaryVal);

        $('.packages-radio-buttons').each(function (){
            $(this).hide();
        });
        $('.packages-desc').each(function (){
            $(this).hide();
        });
        $('#package-car-' + car).show();
        $('#package-car-desc-' + car).show();

        _price = parseInt($(this).data('price'));
    });

    $('.configurator__step_colors input').change(function(){
        var color = $(this).data('id');
        var summaryVal = $(this).val();
        $('.radio-button').removeClass("active");
        $(this).closest('.radio-button').addClass("active");
        $('.configurator__color-choice').removeClass("active");
        $('.configurator__color-choice[data-id="' + color + '"]').toggleClass("active");
        $('.configurator__step_colors .configurator__step-caption-sm').html(summaryVal);
        $('.summary__item_color .summary__value span').html(summaryVal);

        let _src = $(this).data('src');
        $('.configurator__final-pic img').attr('src', _src)
    });

    $('.configurator__step_package .checkbox input').change(function(){
        var _package = $(this).data('id');
        var carId = $(this).data('carId') + '-';
        $('.configurator__package[data-id="' + _package + '"]').toggleClass("active");
        var summaryVal = $(this).val();
        var summary = summaryVal.replace(carId, '');
        if ($(this).closest('.checkbox').hasClass("changed")) {
            $("<span></span>", {text: summary}).attr("data-id", _package).appendTo('.summary__item_package .summary__value');
            _price += parseInt($(this).data('price'));
        } else {
            $('.summary__item_package .summary__value span[data-id=' + _package + ']').remove();
            _price -= parseInt($(this).data('price'));
        }

    });

    $('.configurator__step_presents .radio-button input').change(function(){
        $('.configurator__desc .configurator__present-choice li').removeClass("active");
        $('.configurator__desc li[data-id=' + $(this).data('id') + ']').toggleClass("active");
        var summaryVal = $(this).val();
        $('.summary__item_presents .summary__value span').html(summaryVal);
    });
});
