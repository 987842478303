import Swiper from "swiper";
import select2 from "select2";

$(document).ready(function () {

    let menuItem = window.location.href;
    let liMenuTag = $(`header .menu a[href='${menuItem}']`).parent('li');
    $(liMenuTag[0]).addClass('active');

    $('[data-fancybox=""]').fancybox({
        autoFocus: false
    });

    $.fancybox.defaults.backFocus = false;

    // accordeon

    $('.accordeon__question').click(function () {
        $(this).next().slideToggle(300);
        $(this).toggleClass('active');
    });

// mobile_menu

    $('.header__top-line .burger').click(function () {
        $(this).toggleClass('active');
        $('.header__mobile-nav').slideToggle();
        $('body').toggleClass("dark");
    });

    $(document).mouseup(function (e) {
        if (!$('.header__top-line').is(e.target) && !$('.brands-burger').is(e.target) && !$('.header__mobile-nav').is(e.target) &&
            $('.header__top-line').has(e.target).length === 0 && $('.burger').hasClass('active')) {
            $(".header__mobile-nav").fadeOut();
            $(".brands-line").fadeOut();
            $('body').removeClass("dark");
            $('.header__top-line .burger').removeClass('active');
        }
        if ($('.header__mobile-nav').is(e.target)) {
            $(".brands-line").fadeOut();
            $('.brands-burger').removeClass("active");
        }
    });

    $('footer .burger').click(function () {
        $('footer').find('.navbar').slideToggle(300);
    });

    $('.brands-btn').click(function () {
        $(this).closest(".wrapper").find('.brands-menu__list_full').slideToggle();
        $(this).toggleClass('active');
    });

    $('.header__top-line .brands-burger').click(function () {
        $('.brands-line').slideToggle();
        $('.brands-burger').toggleClass("active");
    });

    $('.header__mobile-nav .brands-burger').click(function () {
        $('.brands-line').slideToggle();
        $('.brands-burger').toggleClass("active");
    });

    $('.header__menu-line .header__right-part .brands-burger').click(function () {
        $('.brands-menu_fixed').slideToggle();
        $(this).toggleClass("active");
    });


// fixed header and compare models

    const hHeight = $(".header").innerHeight(),
        hNext = $(".header").next();

    if ($(".compare-screen__models-line").length) {
        var compareModels = $(".compare-screen__models-line"),
            compareModelsoffset = compareModels.offset().top;
    }

    $(window).scroll(function () {
        var top = $(window).scrollTop();
        let list = $(".toggle-list");
        if (window.innerWidth < 1280) {
            $('.brands-line').slideUp();
            $('.brands-burger').removeClass("active");
            $(".header").addClass("compensate-for-scrollbar");
        } else {
            if (top > ($(".header__top-line").innerHeight())) {
                $(".header").addClass("header-fix").addClass("compensate-for-scrollbar");
                hNext.css({
                    'margin-top': hHeight + 'px'
                });
            } else {
                $(".header").removeClass("header-fix").removeClass("compensate-for-scrollbar");
                hNext.css({
                    'margin-top': '0px'
                });
            }
        }
        if ($('.brands-menu_fixed').css('display') == 'block') {
            $('.brands-menu_fixed').slideUp(300);
            $('.brands-burger').removeClass("active");
        }
        if (list.css('display') == 'block') {
            list.slideUp(500);
        }
        if (compareModels) {
            if (compareModels.length && ((top + 58) >= compareModelsoffset)) {
                compareModels.addClass("fixed");
            } else {
                compareModels.removeClass("fixed");
            }
        }
    });


// favorite icon

    // $('.js-active').click(function (e) {
    //     e.preventDefault();
    //     $(this).toggleClass('active');
    // });
    // filters show

    $('.filters-btn').click(function () {
        $(this).toggleClass('active');
        $('.filters-wr').slideToggle(300);
    });


// select

    $('.select__field').click(function () {
        $(this).next().slideToggle(300);
    });


// select2

    $('.select2').select2({
        width: '100%',
        minimumResultsForSearch: Infinity,
        allowClear: true,
        dropdownCssClass: 'select-new-dropdown'
    });

    $('.select2').on('select2:selecting', function (e) {
        $(this).removeClass("error");
    });

    $('#credit-popup .select2').select2({
        width: '100%',
        minimumResultsForSearch: Infinity,
        allowClear: true,
        dropdownCssClass: 'select-new-dropdown',
        dropdownParent: $('#credit-popup')
    });

    $('.footer__city-select .select2').select2({
        width: '100%',
        minimumResultsForSearch: Infinity,
        allowClear: true,
        dropdownCssClass: 'select-city-dropdown'
    });

// checkbox

    $('.checkbox input').change(function () {
        $(this).closest(".checkbox").toggleClass('changed');
    });

    $('.checkbox-filter').click(function (e) {
        e.preventDefault();
        let ch = $(this).find("input");

        ch.prop("checked", !ch.prop("checked"));
        $(this).toggleClass('changed');

    });

    // $('.checkbox-filter__del').click(function (e) {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     $(this).closest(".checkbox-filter").removeClass('changed');
    //     $(this).closest(".checkbox-filter").find("input").prop("checked", false);
    // });

    $('.filters__clear').click(function () {
        $(this).closest(".filters").find(".checkbox-filter").removeClass('changed');
        $(this).closest(".filters").find("input").prop("checked", false);
    });

// rating circle

    function progressView() {
        const diagramBox = document.querySelectorAll('.diagram');
        diagramBox.forEach((box) => {
            let deg = 360 * box.dataset.rating / 100;
            if (box.dataset.rating >= 50) {
                box.classList.add('over_50');

                box.querySelector('.diagram__piece_right').style.transform = 'rotate(' + (deg - 180) + 'deg)';

            } else {
                box.classList.remove('over_50');
                box.querySelector('.diagram__piece_right').style.transform = 'rotate(' + deg + 'deg)';
            }
        });
    }

    progressView();


// tabs

    $('.simple-tabs__button').click(function () {
        var index = $(this).index();
        var item = $(this).closest('.simple-tabs').find('.simple-tabs__item').eq(index);
        $('.simple-tabs__item').removeClass('active');
        item.addClass('active');
        $('.simple-tabs__button').removeClass('active');
        $(this).addClass('active');
    });

    // sticky close

    $('.sticky__close').click(function () {
        $(this).closest('.sticky').slideUp(300);
    });

// Quiz tabs

    function quizHeight() {
        $('.quiz__content').each(function (index) {
            var h = $(this).find('.quiz__item:first-child').innerHeight();
            $(this).css({
                'height': h + 'px'
            });
        });
    };

    $('.quiz .button_next-step').click(function () {
        var inputsGroup = $(this).closest('fieldset');
        if ($(this).hasClass('button_validate')) {
            inputsGroup.find('.select2[name="brand"]').valid();
            inputsGroup.find('.select2[name="model"]').valid();
            inputsGroup.find('.select2[name="equipment"]').valid();
        }

        if (inputsGroup.find('.select2').hasClass('error')) {
            return;
        } else {
            let index = $(this).closest('.quiz__item').index() + 1;
            let quiz_content = $(this).closest('.quiz__content').find('.quiz__item').eq(index);
            quiz_content.show(250);
            $('.quiz__button').each(function () {
                var indexButton = $(this).index();
                if (index >= indexButton) {
                    $(this).addClass('active');
                }
            });
            $('.quiz__content').css({
                'margin-left': '-' + index + '00%'
            });
        }
    });

    $('.quiz .button_prev-step').click(function () {
        let index = $(this).closest('.quiz__item').index() - 1;
        let quiz_content = $(this).closest('.quiz__content').find('.quiz__item').eq(index);
        quiz_content.show(250);
        $('.quiz__button').each(function () {
            var indexButton = $(this).index();
            if ((index + 1) == indexButton) {
                $(this).removeClass('active');
            }
        });
        $('.quiz__content').css({
            'margin-left': '-' + index + '00%'
        });
    });

    // credit calc

    var percentDone = 0;
    var prevPercent = 0;
    var difPercent = 0;
    var progressMax = false
    var modelTrigger = false;
    var equipTrigger = false;

    $('.credit-cols .select2[name="brand"]').on('select2:selecting', function (e) {
        $(this).closest(".calc-form__item").addClass("done");
        $(this).closest(".calc-form__item").removeClass("animated");
        if($('.credit-cols input[name="name"]').hasClass("valid") && $('.credit-cols input[name="date"]').hasClass("valid") && $('.credit-cols input[name="tel"]').hasClass("valid")) {
            $(".calc-form__item_final").addClass("done");
        }

        let selectedBrand = $('.credit-cols .select2[name="brand"] option:selected').data("id");

        let parent = $(this).parents('form').first();
        let modelSelect = $('.credit-cols .select2[name="model"]', parent);

        $(modelSelect)
            .find('option')
            .remove()
            .end();

        $.ajax({
            method: "GET",
            url: `/getModel/` + selectedBrand,
            success: function (data) {
                $(modelSelect).html(data);
            }
        });
    });

    $('.credit-cols .select2[name="model"]').on('select2:selecting', function (e) {
        $(".credit-cols__unknown").removeClass("active");
        $(".credit-cols__choice").addClass("active");
        if (!modelTrigger) {
            percentDone = percentDone + 5;
            $(".calc-form__item_auto .progress__done").css({
                'width': "5%"
            });
        }

        if (!progressMax && !modelTrigger) {
            $(".calc-form__item_credit .progress__done").css({
                'width': percentDone + "%"
            });
            $(".calc-form__item_final .progress__done").css({
                'width': percentDone + "%"
            });
        }

        modelTrigger = true;

        let eqSelect = $('.credit-cols .select2[name="equipment"]');
        let img = $('.credit-cols .select2[name="model"]').find(":selected").data('img');
        let colors = $('.credit-cols .select2[name="model"]').find(":selected").data('colors');

        $('.credit-step__photo').html('<img src="' + img + '" alt="">');
        drawCarImagesDOM(colors);
        drawCarColorsDOM(colors);
        if ($(eqSelect).length > 0)
            getEquipmentData($(this));
    });

    $('.credit-cols .select2[name="equipment"]').on('select2:selecting', function (e) {
        $('.calc-form__item_credit').removeClass("disable");

        if (!equipTrigger) {
            percentDone = percentDone + 5;
            $(".calc-form__item_auto .progress__done").css({
                'width': "10%"
            });
        }

        if (!progressMax && !equipTrigger) {
            $(".calc-form__item_credit .progress__done").css({
                'width': percentDone + "%"
            });
            $(".calc-form__item_final .progress__done").css({
                'width': percentDone + "%"
            });
        }

        equipTrigger = true;

        let option = $('select[name="equipment"] option:selected');
        // console.log(data)
        $('.price').text(option.data('price'))
        $('.credit-step__price-discount').text(option.data('discount'))

        $('.monyhly').text(option.data('monthly'))

        let priceRaw = Number(String($('.credit-cols .select2[name="equipment"] option:selected').data('priceRaw')).replace(/\s/g, ''));
        let percent = $("#percent-range").slider("value");
        let percentInstallment = $("#percent-range-installment").slider("value");
        let months = $("#term-range").slider("value");
        let monthsInstallment = $("#term-range-installment").slider("value");

        let formattedPayment = getInitialPayment(priceRaw, percent);

        $('#vznos-input').val(formattedPayment);

        let monthly = $('#car-credit-itog-monthly');
        let vznos = $('#car-credit-itog-vznos');
        let monthlyValue = priceFormat(creditCalc(priceRaw, months, formattedPayment));

        monthly.html(monthlyValue + ' руб/мес')
        vznos.html(priceFormat(formattedPayment + ' руб'));
    });

    $('.credit-cols .wpcf7-form-control[name="date"]').on('change', function (e) {
        percentDone = percentDone + 10;

        $(".calc-form__item_final .progress__done").css({
            'width': percentDone + "%"
        });

        if (!progressMax) {
            $(".calc-form__item_final .progress__done").css({
                'width': percentDone + "%"
            });
        } else {
            $(".calc-form__item_final .progress__done").css({
                'width': "95%"
            });
        }
    });

    $('.credit-cols .wpcf7-form-control[name="city"]').on('change', function (e) {
        percentDone = percentDone + 10;

        $(".calc-form__item_final .progress__done").css({
            'width': percentDone + "%"
        });

        if (!progressMax) {
            $(".calc-form__item_final .progress__done").css({
                'width': percentDone + "%"
            });
        } else {
            $(".calc-form__item_final .progress__done").css({
                'width': "95%"
            });
        }
    });

    function drawCarImagesDOM(images) {
        let el = $('#car-images');

        el.html('');

        for (let key in images) {
            console.log(key);
            if (key == 0) {
                $('#car-credit-mob-image').attr("src", '/storage' + images[key]['image'])
                el.append($("<img>").attr("src", '/storage' + images[key]['image']).attr("data-id", images[key]['rgb']).attr("class", "active"));
            } else {
                el.append($("<img>").attr("src", '/storage' + images[key]['image']).attr("data-id", images[key]['rgb']));
            }
        }
    }

    function drawCarColorsDOM(images) {
        let el = $('#car-colors');

        el.html('');

        for (let key in images) {
            console.log(key);
            if (key == 0) {
                el.append($("<div></div>")
                    .attr("data-id", images[key]['rgb'])
                    .attr("class", "colors__color active")
                    .append($("<div></div>")
                        .attr("class", "colors__color-circle")
                        .attr("style", "background-color: #" + images[key]['rgb'] + ";")
                    )
                    .append($("<div></div>")
                        .attr("class", "colors__color-value")
                        .text(images[key]['name'])
                    )
                );
            } else {
                el.append(
                    $("<div></div>")
                        .attr("data-id", images[key]['rgb'])
                        .attr("class", "colors__color")
                        .append($("<div></div>")
                            .attr("class", "colors__color-circle")
                            .attr("style", "background-color: #" + images[key]['rgb'] + ";")
                        )
                        .append($("<div></div>")
                            .attr("class", "colors__color-value")
                            .text(images[key]['name'])
                        )
                );
            }
        }

        $('.colors__color-circle').on('click', function(){
            let colorParent = $(this).closest('.colors__color');
            let currentColorUrl = $("#car-color-name-page").data('slug');
            let colorSlug = colorParent.data('slug');
            let brandSlug = colorParent.data('brand');
            let modelSlug = colorParent.data('model');
            let colorVal = colorParent.find(".colors__color-value").html();
            $("#car-color-name").html("Цвет: " + colorVal);
            if (currentColorUrl === 'empty') {
                $("#car-page-color-url").html('<a href="/auto/' + brandSlug + '/' + modelSlug + '/colors/' + colorSlug + '/">' + colorVal + '</a>');
            } else if (currentColorUrl === colorSlug) {
                $("#car-page-color-url").html(colorVal);
            } else {
                $("#car-page-color-url").html('<a href="/auto/' + brandSlug + '/' + modelSlug + '/colors/' + colorSlug + '/">' + colorVal + '</a>');
            }
            $('.colors__color').removeClass("active");
            colorParent.addClass("active");
            $('.dif-colors__images img').removeClass("active");
            $('.dif-colors__images img[data-id=' + colorParent.data('id') + ']').addClass("active");
        });
    }

    function getEquipmentData(modelSelect) {
        let model = $(modelSelect).select2('data');
        if (model.length < 1)
            return;

        let versionSelect = $('select[name=equipment]')

        $(versionSelect)
            .find('option')
            .remove()
            .end()

        $.ajax({
            method: "GET",
            url: `/getEquipmentSets/${model[0].id}`,
            success: function (res) {
                // FILL EQUIPMENT_SET SELECT INPUT
                let eqs = res.data.eq

                for (let key in eqs) {
                    $(versionSelect)
                        .append($("<option></option>")
                            .attr("value", key)
                            .attr("data-price", res.data['price'][key] + ' руб')
                            .attr("data-price-raw", res.data['price'][key])
                            .attr("data-discount", res.data['skid'][key] + ' руб')
                            .attr("data-monthly", res.data['mon'][key] + ' руб/мес')
                            .text(eqs[key]));
                }

                $(versionSelect).val(0).trigger('change');
                $(versionSelect).val($('select[name="equipment"] option:first').val()).trigger('change');
            }
        })
    }

    $('.calc-form__item_auto .select2').on('click', function (e) {
        $(".calc-form__item").removeClass("active animated");
        $(".calc-form__item_auto").addClass("active animated");
    });

    $('.calc-form__item_final .wpcf7-form-control').on('focus', function (e) {
        $(".calc-form__item").removeClass("active animated");
        $(".calc-form__item_final").addClass("active animated");
    });

    $('.credit-cols .wpcf7-form-control').on('change', function (e) {
        if ($(".calc-form form").valid()) {
            $(".calc-form__item_final").addClass("done").removeClass("animated");

            if ($(".calc-form__item_credit").hasClass("done")) {
                $(".calc-form__step_final").addClass("active animated");
            }
        }
    });

    $('.calc-form button').on('click', function (e) {
        $(".calc-form__item").removeClass("animated");
        if ($(".calc-form form").valid()) {
            $(".calc-form__validation").removeClass("active");
            $(".calc-form__step_final").removeClass("animated");
        } else {
            $(".calc-form__validation").addClass("active");
        }
    });


    // ui-range
    let termTrigger = false;
    let percentTrigger = false;

    $("#term-range").slider({
        animate: "slow",
        range: "min",
        min: 12,
        max: 84,
        step: 12,
        value: 12,
        create: function (event, ui) {
            let val = $("#term-range").slider("value");
            $("#term").html(val);
        },
        start: function (event, ui) {
            $(".calc-form__item").removeClass("active animated");
            $(".calc-form__item_credit").addClass("active animated");
        },
        slide: function (event, ui) {
            if (ui.value > 0) {

                percentDone = percentDone + 30;

                if (termTrigger) {
                    percentDone = percentDone - 30;
                    termTrigger = false;
                }
                termTrigger = true;

                if (!progressMax) {
                    $(".calc-form__item_credit .progress__done").css({
                        'width': percentDone + "%"
                    });

                    $(".calc-form__item_final .progress__done").css({
                        'width': percentDone + "%"
                    });
                }
            } else if (ui.value == 0) {
                return false;
            }

            $("#range-label_term").html(ui.value + " мес");
            $("#term").html(ui.value);
            $("#input-term").val(ui.value);
        },
        change: function (event, ui) {
            if (ui.value > 0) {
                $(this).closest(".calc-form__item").addClass("done");
                if ($(".calc-form__item_final").hasClass("done")) {
                    $(".calc-form__step_final").addClass("active animated");
                }

                let priceRaw = Number(String($('.credit-cols .select2[name="equipment"] option:selected').data('priceRaw')).replace(/\s/g, ''));
                if (isNaN(priceRaw)) return;

                let percent = $("#percent-range").slider("value");
                let months = ui.value;

                let formattedPayment = getInitialPayment(priceRaw, percent);

                $('#vznos-input').val(formattedPayment);

                let monthly = $('#car-credit-itog-monthly');
                let vznos = $('#car-credit-itog-vznos');
                let monthlyValue = priceFormat(creditCalc(priceRaw, months, formattedPayment));

                monthly.html(monthlyValue + ' руб/мес')
                vznos.html(priceFormat(formattedPayment + ' руб'));
            }
        }
    });

    $("#percent-range").slider({
        animate: "slow",
        range: "min",
        min: 0,
        max: 80,
        step: 10,
        value: 0,
        create: function (event, ui) {
            let val = $("#percent-range").slider("value");
            $("#percent").html(val);
        },
        start: function (event, ui) {
            $(".calc-form__item").removeClass("active animated");
            $(".calc-form__item_credit").addClass("active animated");
        },
        slide: function (event, ui) {
            $("#range-label_percent").html(ui.value + "%");
            $("#percent").html(ui.value);
            $("#input-percent").val(ui.value);

            if (ui.value > 20) {
                $(".calc-form__item_credit .progress__done").css({
                    'width': "95%"
                });
                $(".calc-form__item_final .progress__done").css({
                    'width': "95%"
                });
                progressMax = true;
            } else if (ui.value >= 0 && ui.value <= 20) {

                if (ui.value == 0) {
                    percentDone = percentDone - 35;
                    percentTrigger = false;
                } else {
                    percentDone = percentDone + 35;

                    if (percentTrigger) {
                        percentDone = percentDone - 35;
                        percentTrigger = false;
                    }

                    percentTrigger = true;
                }

                progressMax = false;

                $(".calc-form__item_credit .progress__done").css({
                    'width': percentDone + "%"
                });
                $(".calc-form__item_final .progress__done").css({
                    'width': percentDone + "%"
                });
            }
        },
        change: function (event, ui) {
            let priceRaw = Number(String($('.credit-cols .select2[name="equipment"] option:selected').data('priceRaw')).replace(/\s/g, ''));

            if (isNaN(priceRaw)) return;

            let percent = $("#percent-range").slider("value");
            let months = $("#term-range").slider("option", "value");

            let formattedPayment = getInitialPayment(priceRaw, percent);
            console.log(formattedPayment);

            $('#vznos-input').val(formattedPayment);

            let monthly = $('#car-credit-itog-monthly');
            console.log(monthly.val());

            let vznos = $('#car-credit-itog-vznos');
            let monthlyValue = priceFormat(creditCalc(priceRaw, months, formattedPayment));
            console.log(monthlyValue);

            monthly.html(monthlyValue + ' руб/мес')
            vznos.html(priceFormat(formattedPayment + ' руб'));
        }
    });

    $("#term-range-installment").slider({
        animate: "slow",
        range: "min",
        min: 0,
        max: 36,
        step: 12,
        value: 0,
        create: function (event, ui) {
            let val = $("#term-range-installment").slider("value");
            $("#term").html(val);
        },
        start: function (event, ui) {
            $(".calc-form__item").removeClass("active animated");
            $(".calc-form__item_credit").addClass("active animated");
        },
        slide: function (event, ui) {
            if (ui.value > 0) {

                percentDone = percentDone + 30;

                if (termTrigger) {
                    percentDone = percentDone - 30;
                    termTrigger = false;
                }
                termTrigger = true;

                if (!progressMax) {
                    $(".calc-form__item_credit .progress__done").css({
                        'width': percentDone + "%"
                    });

                    $(".calc-form__item_final .progress__done").css({
                        'width': percentDone + "%"
                    });
                }
            } else if (ui.value == 0) {
                return false;
            }

            $("#range-label_term-installment").html(ui.value + " мес");
            $("#term").html(ui.value);
            $("#input-term").val(ui.value);
        },
        change: function (event, ui) {
            if (ui.value > 0) {
                $(this).closest(".calc-form__item").addClass("done");
                if ($(".calc-form__item_final").hasClass("done")) {
                    $(".calc-form__step_final").addClass("active animated");
                }

                let priceRaw = Number(String($('.credit-cols .select2[name="equipment"] option:selected').data('priceRaw')).replace(/\s/g, ''));

                if (isNaN(priceRaw)) return;

                let percent = $("#percent-range-installment").slider("value");
                let months = ui.value;

                let formattedPayment = getInitialPayment(priceRaw, percent);

                $('#vznos-input').val(formattedPayment);

                let monthly = $('#car-credit-itog-monthly');
                let vznos = $('#car-credit-itog-vznos');
                let monthlyValue = priceFormat(installmentCalc(priceRaw, months, formattedPayment));

                monthly.html(monthlyValue + ' руб/мес')
                vznos.html(priceFormat(formattedPayment + ' руб'));
            }
        }
    });

    $("#percent-range-installment").slider({
        animate: "slow",
        range: "min",
        min: 30,
        max: 80,
        step: 10,
        value: 30,
        create: function (event, ui) {
            let val = $("#percent-range-installment").slider("value");
            $("#percent").html(val);
        },
        start: function (event, ui) {
            $(".calc-form__item").removeClass("active animated");
            $(".calc-form__item_credit").addClass("active animated");
        },
        slide: function (event, ui) {
            $("#range-label_percent-installment").html(ui.value + "%");
            $("#percent").html(ui.value);
            $("#input-percent").val(ui.value);

            if (ui.value > 40) {
                $(".calc-form__item_credit .progress__done").css({
                    'width': "95%"
                });
                $(".calc-form__item_final .progress__done").css({
                    'width': "95%"
                });
                progressMax = true;
            } else if (ui.value >= 0 && ui.value <= 40) {

                if (ui.value == 0) {
                    percentDone = percentDone - 35;
                    percentTrigger = false;
                } else {
                    percentDone = percentDone + 35;

                    if (percentTrigger) {
                        percentDone = percentDone - 35;
                        percentTrigger = false;
                    }

                    percentTrigger = true;
                }

                progressMax = false;

                $(".calc-form__item_credit .progress__done").css({
                    'width': percentDone + "%"
                });
                $(".calc-form__item_final .progress__done").css({
                    'width': percentDone + "%"
                });
            }
        },
        change: function (event, ui) {
            let priceRaw = Number(String($('.credit-cols .select2[name="equipment"] option:selected').data('priceRaw')).replace(/\s/g, ''));

            if (isNaN(priceRaw)) return;

            let percent = $("#percent-range-installment").slider("value");
            let months = $("#term-range-installment").slider("option", "value");

            let formattedPayment = getInitialPayment(priceRaw, percent);
            console.log(formattedPayment);

            $('#vznos-input').val(formattedPayment);

            let monthly = $('#car-credit-itog-monthly');
            console.log(monthly.val());

            let vznos = $('#car-credit-itog-vznos');
            let monthlyValue = priceFormat(installmentCalc(priceRaw, months, formattedPayment));
            console.log(monthlyValue);

            monthly.html(monthlyValue + ' руб/мес')
            vznos.html(priceFormat(formattedPayment + ' руб'));
        }
    });

    function priceFormat(str) {
        return String(str).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    }

    function getInitialPayment(price, percent) {
        return Math.ceil(Math.round(price * (percent / 100)));
    }

    function creditCalc(price, months = 84, initialPayment = 0) {
        price -= initialPayment;
        if (price < 0) {
            return 0;
        }

        let fullCreditPrice = price * 0.9 * 1.12;
        return Math.ceil(Math.round((fullCreditPrice) / months) / 100) * 100;
    }

    function installmentCalc(price, months = 36, initialPayment = 0) {
        price -= initialPayment;
        if (price < 0) {
            return 0;
        }

        return Math.ceil(Math.round((price) / months) / 100) * 100;
    }

// hidden text

    $('.js-columns').click(function () {
        $(this).closest('.more-parent').addClass('one-col').find('.more-info').slideDown(500);
        $(this).slideUp(0);
    });


// models-toggle

    var swiperModels = undefined;

    function initModelsSwiper() {
        var swiperModels = new Swiper('.swiper-models .swiper-container', {
            slidesPerView: "auto",
            spaceBetween: 15,
            navigation: {
                nextEl: '.toggle-list .swiper-button-next',
                prevEl: '.toggle-list .swiper-button-prev',
            },
        });
    };

    $('.toggle-caption').click(function (e) {
        e.preventDefault();
        $(this).closest('.car-main').find('.toggle-list').slideToggle(500);
        initModelsSwiper();
    });

    $(document).mouseup(function (e) {
        let list = $(".toggle-list");
        if (!list.is(e.target) && list.has(e.target).length === 0 && list.css('display') == 'block') {
            list.slideToggle(500);
        }
    });

// scroll

    $('.scroll-link').on('click', function () {
        var el = $(this);
        var dest = el.attr('href');
        if (dest !== undefined && dest !== '') {
            $('html').animate({
                    scrollTop: $(dest).offset().top - 50
                }, 500
            );
        }
        return false;
    });


// colors change

    $('.colors__color-circle').on('click', function(){
        let colorParent = $(this).closest('.colors__color');
        let currentColorUrl = $("#car-color-name-page").data('slug');
        let colorSlug = colorParent.data('slug');
        let brandSlug = colorParent.data('brand');
        let modelSlug = colorParent.data('model');
        let colorVal = colorParent.find(".colors__color-value").html();
        $("#car-color-name").html("Цвет: " + colorVal);
        if (currentColorUrl === 'empty') {
            $("#car-page-color-url").html('<a href="/auto/' + brandSlug + '/' + modelSlug + '/colors/' + colorSlug + '/" style="display: contents;">' + colorVal + '</a>');
        } else if (currentColorUrl === colorSlug) {
            $("#car-page-color-url").html(colorVal);
        } else {
            $("#car-page-color-url").html('<a href="/auto/' + brandSlug + '/' + modelSlug + '/colors/' + colorSlug + '/" style="display: contents;">' + colorVal + '</a>');
        }
        $('.colors__color').removeClass("active");
        colorParent.addClass("active");
        $('.dif-colors__images img').removeClass("active");
        $('.dif-colors__images img[data-id=' + colorParent.data('id') + ']').addClass("active");
    });



    // $('.colors__color-circle:first').click();


    // accordion

    $('.accordion__answer').hide();

    $('.accordion__question-caption').click(function () {
        $(this).closest('.accordion__question').toggleClass('active').next().slideToggle(500);
        $(this).toggleClass('active');
    });

    $('.footer__col-heading').click(function(){
        if(window.innerWidth < 1024) {
            $(this).toggleClass('active');
            $(this).closest('.footer__inner-col').find('ul').slideToggle(500);
        }
    });

    $('.footer__more-btn').on('click', function(){
        $(this).toggleClass('active');
        $(this).closest('.footer__list-cols').find('.footer__list-hidden').slideToggle(500);
    });

    // toggle table body and rows

    $(".compare-table__header").click(function () {
        $(this).toggleClass("active");
        $(this).next().slideToggle(300);
    });

    $(".compare-table__row .compare-table__title").on('click', function () {
        $(this).toggleClass("active");
        $(this).closest(".compare-table__row").find(".compare-table__values").slideToggle(300);
    });

    // highlight different values

    const compareRow = document.querySelectorAll(".compare-screen .compare-table__values:not(.compare-table_disable)"),
        checkDifferentVal =
            document.querySelector(".js-highlight-row input");

    if (checkDifferentVal) {
        checkDifferentVal.addEventListener("click", function (event) {
            if (event.target.checked == true) {
                console.log("click");

                compareRow.forEach(item => {
                    const rowField = item.querySelectorAll(".compare-table__value");
                    const fieldValues = [];
                    rowField.forEach(field => {
                        const fieldVal = field.textContent;
                        fieldValues.push(fieldVal);
                    });
                    for (let i = 0; i < fieldValues.length; i++) {
                        if (fieldValues[0] !== fieldValues[i]) {
                            item.classList.add("highlighted");
                            break;
                        }
                    }
                    // if (itemParent !== $(item).parent())
                    // {
                    //     let title = $(item).parent().closest('.compare-table__title');
                    //     console.log(title)
                    //     title.addClass('active')
                    //     $(title).closest(".compare-table__row").find(".compare-table__values").slideToggle(300);
                    //     itemParent = $(item).parent();
                    // }

                });
                $('.compare-table__row').each(function () {

                    if ($(this).find('.highlighted').length < 1)
                        return;

                    $(this).closest('.compare-table__title').addClass('active')
                    $(this).find(".compare-table__values").show(300);
                });

            } else {
                compareRow.forEach(item => {
                    item.classList.remove("highlighted");
                });
            }
        });
    }

    $(window).resize();
});
